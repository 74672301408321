<template>
<div class="page-title camel-case">
      {{title}}
</div>
</template>

<script>
export default {
      name: 'Title',
      props: ["title"]
};
</script>
